console.log("version 9/4/24 neatsy");

let _BGV_SETTINGS = window.BGV_SETTINGS = window.BGV_SETTINGS || {}

// PROD
_BGV_SETTINGS.ENV = 'PROD'
_BGV_SETTINGS.PARTNER_ID = '019081d4-25a8-a2a0-33f4-9580bdbc9451'
_BGV_SETTINGS.APP_ID = '019081d5-4c13-654c-130c-a1de1f63323a'
_BGV_SETTINGS.PIXEL_ID = '2187572274945236'

// ------------------ EDIT for each new flow ----------------------
_BGV_SETTINGS.PAGE_SET_ID = '01916ff8-ce18-e5d7-5ecf-a6a5d6e1e96b'
// ------------------ EDIT for each new flow ----------------------





_BGV_SETTINGS.renewDisclaimerConf = {
    afterSelectPlanBlock: false,
    afterSubmitButton: true,
};

_BGV_SETTINGS.timerConfig = {
    enable: true,
    buttonText: "Get my plan",
    countDownSeconds: 60 * 5, // 5 minutes,
    timerDescription: "⏱️ 40% off for:",
}

_BGV_SETTINGS.lottieConfiguration = {
    'select-plan':{
        'Before-after': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-08/Before-After_Neatsy_animation.json',
            'loop': true,
        }
    },
}


